import { Link } from '@remix-run/react';
import React from 'react';
import { FAQ_LINK } from '~/constants';
import { useTranslation } from '~/i18n';
import { cn } from '~/utils/cn';
import { Section, SectionContent, SectionHeader, SectionTitle } from './Section';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from './ui/Accordion';
import { buttonVariants } from './ui/Button';
import { Image } from './ui/Image';
import { HeadingM, textVariants } from './ui/Typography';

const FaqContext = React.createContext<
  [
    string | (readonly string[] & string & {}) | undefined,
    React.Dispatch<React.SetStateAction<string | undefined>>,
  ]
>([undefined, () => {}]);

export function Faq({
  children,
  defaultValue,
  ...props
}: React.ComponentProps<'section'> & { defaultValue?: string }) {
  const { t } = useTranslation();
  const activeItem = React.useState(defaultValue);

  return (
    <Section {...props} className={cn('max-w-screen-xl mx-auto', props.className)}>
      <SectionContent className="grid">
        <SectionHeader>
          <SectionTitle>{t('do-you-still-have-questions')}</SectionTitle>
        </SectionHeader>

        <FaqContext.Provider value={activeItem}>
          <div className="flex flex-col items-center gap-10 md:items-start">
            <Accordion
              type="single"
              value={activeItem[0]}
              onValueChange={activeItem[1]}
              className="w-full"
              defaultValue={defaultValue}
              collapsible
            >
              {children}
            </Accordion>
            <Link className={buttonVariants()} to={FAQ_LINK}>
              {t('go-to-faq')}
            </Link>
          </div>
        </FaqContext.Provider>
      </SectionContent>
    </Section>
  );
}

export function FagImage({ src, alt }: { src: string; alt: string }) {
  return (
    <div>
      <div className="relative h-[552px] overflow-x-clip md:size-full md:max-w-[510px] md:overflow-x-visible">
        <Image
          src={src}
          alt={alt}
          className="h-[552px] rounded-lg object-cover object-center md:size-full md:max-w-[510px]"
          height={552}
        />
      </div>
    </div>
  );
}

export function FaqItem({
  title,
  value,
  ...props
}: { title: string; value: string } & React.ComponentProps<typeof AccordionContent>) {
  const [activeTab] = React.useContext(FaqContext);

  return (
    <AccordionItem value={value} className={textVariants({ size: 'm' })}>
      <AccordionTrigger className="[&_svg]:size-6">
        <HeadingM asChild>
          <h3>{title}</h3>
        </HeadingM>
      </AccordionTrigger>
      <AccordionContent
        forceMount
        hidden={activeTab !== value}
        {...props}
        className={textVariants({
          size: 'paragraph-expanded',
          class: 'font-medium [&_a]:underline',
        })}
      />
    </AccordionItem>
  );
}
